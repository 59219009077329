<template>
  <div class="adviceBox">
    <!-- tabs标签页 -->
    <div>
      <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
        <el-tab-pane label="组套管理" name="1">
          <manage ref="manages"></manage>
        </el-tab-pane>
        <el-tab-pane label="绑定组套" name="2">
          <binding ref="bindings"> </binding>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script>
import { toRefs, reactive, ref } from 'vue'
import manage from './manage.vue'
import binding from './binding .vue'
export default {
  components: {
    manage,
    binding,
  },
  setup() {
    const state = reactive({
      activeName: '1',
    })
    const bindings = ref()
    const manages = ref()
    // tabs切换
    const handleClick = () => {
      if (state.activeName == '2') {
        bindings.value.project()
      } else {
        manages.value.tobody()
      }
    }
    return {
      ...toRefs(state),
      handleClick,
      bindings,
      manages,
    }
  },
}
</script>

<style scoped lang="scss">
.adviceBox {
  height: 728px;
  background: #ffffff;
  border-radius: 8px 8px 8px 8px;
  opacity: 1;
  padding: 10px;
}
</style>
